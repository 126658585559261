import React from 'react';
import InspireVideo from "../../videos/inspires-ampm.webm";
import {useTranslation} from "react-i18next";

const VideoWords = () => {
  const {t} = useTranslation();

  return (
    <div className="video-words">
      <div className="video-words__wrapper wrapper">
        <div className="video-words__content">
          <h1 className="big-title video-words__title" dangerouslySetInnerHTML={{__html:t("videoWords")}}/>
          <div className="video-words__container">
            <video className="video-words__bg-video" playsInline autoPlay loop muted>
              <source src={InspireVideo} type="video/webm"/>
            </video>
            <p className="video-words__text">{t("animatedWords")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoWords;
