import React from 'react';

const BrandLead = ({title, text, image, alt}) => {
  return (
    <section className="ampm-lead">
      <div className="ampm-lead__wrapper wrapper">
        <h1 className="big-title ampm-lead__title">{title}</h1>
        <p className="ampm-lead__text">{text}</p>
        <div className="ampm-lead__image-wrapper">
          <img src={image} alt={alt} width="740"/>
        </div>
      </div>
    </section>
  );
};

export default BrandLead;
