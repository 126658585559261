import React from 'react';

const DoubleBlock = ({text, linkHref, linkText, video}) => {
  return (
    <article className="double-block">
      <div className="double-block__wrapper">
        <div className="blue-block double-block__blue blue-block--right">
          <p>
            {text}
          </p>
          <a href={linkHref} className="blue-block__link">{linkText}</a>
        </div>
        <div className="double-block__image-wrapper">
          <video className="double-block__video-item" playsInline autoPlay loop muted>
            <source src={video} type="video/webm"/>
          </video>
        </div>
      </div>
    </article>
  );
};

export default DoubleBlock;
