import React from 'react';


const History = ({image, alt, text}) => {
  return (
    <section className="history">
      <div className="history__wrapper wrapper">
        <div className="history__content">
          <div className="history__image-wrapper">
            <img src={image} alt={alt} className="history__image"/>
          </div>
          <div className="history__text-content">
            <p>{text}
            </p>
          </div>
          <div className="history__pink-block" />
        </div>
      </div>
    </section>
  );
};

export default History;
