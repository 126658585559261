import React from 'react';

const Feature = ({title, text, image, alt}) => {
  return (
    <section className="feature">
      <div className="feature__wrapper wrapper">
        <h3 className="feature__title">{title}</h3>
        <div className="feature__double-block">
          <div className="feature__text-block blue-block">
            <p>
              {text}
            </p>
          </div>
          <div className="feature__image-wrapper">
            <img src={image} alt={alt}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
