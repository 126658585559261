import React from 'react';
import Header from "../components/sections/header";
import VideoWords from "../components/sections/video-words";
import History from "../components/sections/history";
import Feature from "../components/sections/feature";
import Footer from "../components/sections/footer";
import DoubleBlock from "../components/sections/double-block";
import BrandLead from "../components/sections/brand-lead";
import AmpmBrandImage from "../images/ampm-brand-image.png";
import {useTranslation} from "react-i18next";
import AmpmHistoryImage from "../images/ampm-brand-image-2.jpg";
import AmpmFeatureImage from "../images/ampm-feature.png";
import DoubleAmpmVideo from "../videos/double-ampm.webm";

const Ampm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <BrandLead title={t("brandAmpm.title")} text={t("brandAmpm.leadText")} alt="am.pm" image={AmpmBrandImage} />
      <History image={AmpmHistoryImage} alt="ampm history image" text={t("brandAmpm.historyText")} />
      <Feature title={t("brandAmpm.featureTitle")} text={t("brandAmpm.featureText")} image={AmpmFeatureImage} alt="ampm feature" />
      <VideoWords />
      <DoubleBlock text={t("brandAmpm.doubleBlockText")} linkText={t("brandAmpm.doubleBlockLink")} linkHref="https://ampm.ua/" video={DoubleAmpmVideo} />
      <Footer/>
    </>
  );
};

export default Ampm;
